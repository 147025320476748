<app-nav-bar></app-nav-bar>

<div class="container form-main">
    <div class="row">
        <div class="col-md-5">
           <div class="form-descr card info-card mb-3">
                <h4 class="descr-title">Resource Details</h4>
                <p>You have three options for getting compensated for the resource item you are sharing. 
                You can choose to sell it, give it away, or barter it for something of an equivalent value. 
                See more information about our Barter System (WAgC Barter)</p>
                <p>We strongly recommend turning on location services to get a more accurate location for your posting.</p>
            </div>
        </div>
        <div class="col-md">
            <div class="form-card card info-card mb-3">
                <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Title</mat-label>
                            <input matInput #inputtitle maxlength="50" formControlName="title" type="text">
                            <mat-hint align="end">{{inputtitle.value?.length || 0}}/50</mat-hint>
                        </mat-form-field>
                        <mat-error class="error-msg" *ngIf="message && message.obj.title">
                            {{message.obj.title}}
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Description</mat-label>
                            <textarea matInput #inputdesc maxlength="250" formControlName="description"></textarea>
                            <mat-hint align="end">{{inputdesc.value?.length || 0}}/250</mat-hint>
                        </mat-form-field>
                        <mat-error class="error-msg" *ngIf="message && message.obj.description">
                            {{message.obj.description}}
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type">
                            <mat-option value="Goods">Goods</mat-option>
                            <mat-option value="Services">Services</mat-option>
                            <mat-option value="Jobs">Jobs</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="error-msg" *ngIf="message && message.obj.type">
                            {{message.obj.type}}
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>Category</mat-label>
                            <mat-select formControlName="category">
                            <mat-option value="Tools & Equipment">Tools & Equipment</mat-option>
                            <mat-option value="Seeds & Crops">Seeds & Crops</mat-option>
                            <mat-option value="Consulting">Consulting</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="error-msg" *ngIf="message && message.obj.category">
                            {{message.obj.category}}
                        </mat-error>
                    </div>
                    <div class="form-row">
                        <div class="col-md-5 mb-3 justify-content-end">
                            <button mat-flat-button color="primary" type="submit">Add Resource</button>
                        </div>
                        <!-- [disabled]="!resourceForm.valid" -->
                    </div>
                </form>  
            </div>                   
        </div>
    </div>
</div>