import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
// import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { Register } from '../models/register'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currUserSubject: BehaviorSubject<User>;
  public currUser: Observable<User>;
  private authUrl = 'http://localhost:8000/rest-auth/';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
  'Server': 'WSGIServer/0.2 CPython/3.8.3' })
  };

  constructor(private http: HttpClient) {
    this.currUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currUser = this.currUserSubject.asObservable();
   }

   public get currUserToken(): User {
    return this.currUserSubject.value;
  }

  login(user: User): Observable<User> {
    return this.http.post<User>(this.authUrl + "login/", user, this.httpOptions)
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.key) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currUserSubject.next(user);
      }
      return user;
    }));
  }

  register(register: Register): Observable<User> {
    return this.http.post<User>(this.authUrl + "registration/", register, this.httpOptions)
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.key) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currUserSubject.next(user);
      }
      return user;
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currUserSubject.next(null);
  }
}



