<form [formGroup]="loginForm" class="auth-form-top">
    <div class="form-group"> 
        <mat-error class="error-msg" *ngIf="message && message.obj.non_field_errors">
            {{message.obj.non_field_errors}}
        </mat-error>
    </div> 
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Email</mat-label>
            <input matInput formControlName="username_email" placeholder="or username">
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.email">
            {{message.obj.email}}
        </mat-error>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.password">
            {{message.obj.password}}
        </mat-error>
    </div>
    <button mat-flat-button (click)="onSubmit()" class="text-white w-100" color="primary">Login</button>
</form>

<!-- <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" role="form" class="auth-form-top"> 
    <div class="form-group"> 
        <div *ngIf="message && message.obj.non_field_errors" class="alert alert-danger">{{message.obj.non_field_errors}}</div>
    </div>  
    <div class="form-group"> 
        <label for="username_email">Email or Username</label>         
        <input type="text" class="form-control" formControlName="username_email"> 
    </div> 
    <div class="form-group"> 
        <div *ngIf="message && message.obj.email" class="alert alert-danger">{{message.obj.email}}</div>
        <div *ngIf="message && message.obj.username" class="alert alert-danger">{{message.obj.username}}</div>
    </div>    
    <div class="form-group"> 
        <label for="password">Password</label>         
        <input type="password" class="form-control" formControlName="password"> 
    </div>
    <div class="form-group"> 
        <div *ngIf="message && message.obj.password" class="alert alert-danger">{{message.obj.password}}</div>
    </div>      
    <button type="submit" class="btn btn-primary">Login</button>     
</form> -->
