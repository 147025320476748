import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { Subscription } from 'rxjs';
import { Register } from '../models/register'
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  private subscription: Subscription;
  message: any;
  hide = true;

  constructor(private authService: AuthService, 
    private alertService: AlertService,
    private fb: FormBuilder, 
    private router: Router) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      username: [''],
      email: [''],
      password1: [''],
      password2: [''],
    });

    this.subscription = this.alertService.getAlert()
    .subscribe(message => {
        this.message = message;
    });
  }

  onSubmit() {
    this.register_user(this.registerForm.value as Register);
  }

  register_user(register_cred: Register): void {
    this.authService.register(register_cred)
    .pipe(first())
    .subscribe(
        data => {
            this.router.navigate(['/']);
        },
        error => {
          this.alertService.error(error);
        });
  }

}
