<nav class="bg-light main-bar navbar navbar-expand-md navbar-light sticky-top">
    <button mat-icon-button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#mainBarToggler" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <mat-icon>menu</mat-icon>
    </button>
    <button mat-button routerLink='/posts'><img src="assets/logo.png" width="100"/></button>
    <!-- <a routerLink="/posts"><img src="assets/logo.png" width="100" class=" navbar-brand"/></a> -->
    <div class="collapse navbar-collapse" id="mainBarToggler">
        <form class="form-inline mr-auto mt-lg-0">
            <!-- <mat-form-field floatLabel='never' class="search-box"> 
                  <input matInput type="text" placeholder="Search for Resources">
            </mat-form-field> -->
            <input (keyup.enter)="goSearch()" type="search" [formControl]="searchIn" class="search-box" placeholder="Search for Resources"/>
            <button (click)="goSearch()" mat-flat-button class="search-btn" color="primary">
              <mat-icon>search</mat-icon>
            </button>
        </form>
        <ul class="navbar-nav">
          <li class="nav-item">
            <button mat-icon-button [matMenuTriggerFor]="menu" color="primary" matTooltip="Account"><mat-icon>account_box</mat-icon></button>
              <mat-menu class="account-menu" #menu="matMenu">
                <button class="account-menu" mat-menu-item (click)="logout()">Logout</button>
                <button class="account-menu" mat-menu-item>Settings</button>
              </mat-menu>
              <!-- <div class="dropdown">
                  <button class="dropdown-toggle btn bg-transparent border-0 acc-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Account
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="logout()">Logout</a>
                  </div>
                </div> -->
          </li>
          <li class="nav-item">
            <button mat-icon-button routerLink='/add-new-resource' color="primary" matTooltip="Add Resource"><mat-icon>add_box</mat-icon></button>
              <!-- <button class="btn bg-transparent border-0" type="button" routerLink='/add-new-resource'>
                  Post
              </button> -->
          </li>
      </ul>
    </div>
  </nav>
