import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from '../models/post';
import { PostsService } from '../services/posts.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: '<all-posts>',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {
  posts: Post[];
  orderIn = new FormControl('title');
  typeIn = new FormControl('all');

  constructor(private router: Router,
    private route: ActivatedRoute,
    private postService: PostsService) { }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if (!(Object.keys(params).length === 0)) {
        if (params.ordering) {
          this.orderIn.setValue(params.ordering);
        } else if (params.type) {
          this.typeIn.setValue(params.type);
        }
        
        this.filterPosts(params);
      } else {
        this.orderIn.setValue('title');
        this.typeIn.setValue('all');
        this.getPosts();
      }
      // if (params.search) {
      //   this.searchPosts(params.search);
      // } else {
      //   this.getPosts();
      // }
    });
  }

  getPosts(): void {
    console.log("normal");
    this.postService.getPosts()
    .subscribe(posts => this.posts = posts);
  }

  goOrder(): void {
    if (this.orderIn.value != "title") {
      this.router.navigate(['/posts'], 
      { queryParams: { ordering: this.orderIn.value }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/posts'], 
      { queryParams: { ordering: null }, queryParamsHandling: 'merge' });
    }
  }

  goType(): void {
    if (this.typeIn.value != "all") {
      this.router.navigate(['/posts'], 
      { queryParams: { type: this.typeIn.value }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/posts'], 
      { queryParams: { type: null }, queryParamsHandling: 'merge' });
    }
  }

  filterPosts(param_obj: any): void {
    var query_list = [];

    for (let key of Object.keys(param_obj)) {
      query_list.push(key + "=" + encodeURIComponent(param_obj[key]));
    }

    var query_set = '?' + query_list.join("&")

    this.postService.filter(query_set)
    .subscribe(posts => this.posts = posts);
  }
}
