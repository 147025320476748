<form [formGroup]="registerForm" class="auth-form-top">
    <div> 
        <mat-error class="error-msg" *ngIf="message && message.obj.non_field_errors">
            {{message.obj.non_field_errors}}
        </mat-error>
    </div> 
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.username">
            {{message.obj.username}}
        </mat-error>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.email">
            {{message.obj.email}}
        </mat-error>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Enter Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password1">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.password1">
            {{message.obj.password1}}
        </mat-error>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="login-form">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password2">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <!-- <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
        <mat-error class="error-msg" *ngIf="message && message.obj.password2">
            {{message.obj.password2}}
        </mat-error>
    </div>
    <button mat-flat-button (click)="onSubmit()" class="text-white w-100" color="primary">Register</button>

    <!-- <div class="form-group"> 
        <div *ngIf="message && message.obj.non_field_errors" class="alert alert-danger">{{message.obj.non_field_errors}}</div>
    </div>
    <div class="form-group"> 
        <label for="username">Username</label>         
        <input type="text" class="form-control" formControlName="username"> 
    </div>
    <div class="form-group"> 
        <div *ngIf="message && message.obj.username" class="alert alert-danger">{{message.obj.username}}</div>
    </div> 
    <div class="form-group"> 
        <label for="email">Email Address</label>         
        <input type="email" class="form-control" formControlName="email"> 
    </div>  
    <div class="form-group"> 
        <div *ngIf="message && message.obj.email" class="alert alert-danger">{{message.obj.email}}</div>
    </div> 
    <div class="form-group"> 
        <label for="password1">Password</label>         
        <input type="password" class="form-control" formControlName="password1"> 
    </div>
    <div class="form-group"> 
        <div *ngIf="message && message.obj.password1" class="alert alert-danger">{{message.obj.password1}}</div>
    </div> 
    <div class="form-group"> 
        <label for="passwords">Confirm Password</label>         
        <input type="password" class="form-control" formControlName="password2"> 
    </div>
    <div class="form-group"> 
        <div *ngIf="message && message.obj.password2" class="alert alert-danger">{{message.obj.password2}}</div>
    </div>

    <button type="submit" class="btn btn-primary">Register</button>      -->
</form>
