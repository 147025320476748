<app-nav-bar></app-nav-bar>

<div class="post-rows row mt-4 mb-2">
    <div class="col-md-auto">
        <h4 class="post-header-text">Explore Resource Postings</h4>
    </div>
</div>

<div class="post-rows row mb-4">
    <div class="col-md-auto">
        <button mat-stroked-button color="primary" data-toggle="collapse" data-target="#filtersCollapse" aria-expanded="false" aria-controls="filtersCollapse">
            <mat-icon>tune</mat-icon> Show Filters
        </button>
    </div>
</div>

<div class="post-rows row collapse" id="filtersCollapse">
    <div class="col-md-auto mb-2">
        <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [formControl]="typeIn" (selectionChange)="goType()">
                <mat-option value="all">All</mat-option>
                <mat-option value="Goods">Goods</mat-option>
                <mat-option value="Events">Events</mat-option>
                <mat-option value="Educational">Educational</mat-option>
                <mat-option value="Jobs">Jobs</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-auto mb-2">
        <mat-form-field>
            <mat-label>Sort By</mat-label>
            <mat-select [formControl]="orderIn" (selectionChange)="goOrder()">
            <mat-option value="title">Alphabetical</mat-option>
            <mat-option value="-created">Date Added</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="row post-rows">
    <div class="col-md-3" *ngFor="let post of posts">
        <div routerLink="/posts/{{ post.id }}" class="card mb-3 post-card">
            <img src="{{post.image_url}}" class="img-fit"/>
            <div class="card-body">
                <h5 class="post-title card-title">{{ post.title }}</h5>
                <p class="card-text post-title"><small class="post-detail-text text-muted">
                    Posted {{ post.created | getPostTime }} ago</small></p>
            </div>
        </div>
    </div>
</div>


