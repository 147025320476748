import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
// import { catchError, map, tap } from 'rxjs/operators';
import { Post } from '../models/post';
import { PostDetail } from '../models/post-detail'

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  private postsUrl = 'http://localhost:8000/posts/';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
  'Server': 'WSGIServer/0.2 CPython/3.8.3' })
  };

  constructor(
    private http: HttpClient) { }

  getPosts(): Observable<Post[]> {
    return this.http.get<Post[]>(this.postsUrl);
  }

  /** POST: add a new hero to the database */
  addPost(detail: PostDetail): Observable<PostDetail> {
    return this.http.post<PostDetail>(this.postsUrl, detail, this.httpOptions);
  }

  getPost(id:number): Observable<PostDetail> {
    const url = `${this.postsUrl}${id}`;
    return this.http.get<PostDetail>(url);
  }

  filter(query_set:string): Observable<Post[]> {
    return this.http.get<Post[]>(this.postsUrl + query_set);
  }
}

