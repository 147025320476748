import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { PostDetail } from '../models/post-detail';
import { PostsService } from '../services/posts.service';
import { AlertService } from '../services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.css']
})
export class AddFormComponent {

  resourceForm: FormGroup;
  message: any;
  private subscription: Subscription;

  constructor(private postService: PostsService, 
    private fb: FormBuilder, 
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.resourceForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      type: ['', Validators.required]
    });

    this.subscription = this.alertService.getAlert()
    .subscribe(message => {
        this.message = message;
    });
  }

  onSubmit() {
    this.add(this.resourceForm.value as PostDetail);
    // this.router.navigate(['/posts']);
  }

  add(new_post: PostDetail): void {
    this.postService.addPost(new_post)
    .subscribe(
        data => {
            this.router.navigate(['/']);
        },
        error => {
          this.alertService.error(error);
        });
  }

}
