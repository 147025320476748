<nav class="bg-light login-bar navbar navbar-expand-lg navbar-light sticky-top">
    <a class="wagc-logo"><img src="assets/logo.png" width="100" class="navbar-brand"/></a>
</nav>

<div class="container auth-main">
    <div class="form-main row">
        <div class="col-md col-sm">
            <div class="app-descr card info-card mb-3">
                <h2 class="descr-title">Share and Find Agricultural and Food Access Resources All Over Pennsylvania</h2>
                <hr color="white"/>
                <h6 class="descr-text"><i class="icon-align material-icons">check</i> Find Free Resources or Barter</h6>
                <h6 class="descr-text"><i class="icon-align material-icons">check</i> Location Based Search</h6>
                <h6 class="descr-text"><i class="icon-align material-icons">check</i> Connect, Learn, and Network</h6>
            </div>
        </div>
        <div class="col-md">
            <nav mat-tab-nav-bar>
                <a mat-tab-link class="auth-tabs"
                [routerLink]="['/auth/login']"
                routerLinkActive #rla1="routerLinkActive"
                [active]="rla1.isActive">Login
                </a>
                <a mat-tab-link class="auth-tabs"
                [routerLink]="['/auth/register']"
                routerLinkActive #rla2="routerLinkActive"
                [active]="rla2.isActive">Register
                </a>
            </nav>

            <!-- <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink='/auth/login'>Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink='/auth/register'>Register</a>
                </li>
            </ul> -->
            <router-outlet></router-outlet>           
        </div>
    </div>
</div>
