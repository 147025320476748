import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getPostTime',
    pure: true
  })
  export class GetPostTimePipe implements PipeTransform {
    transform(date: string, args?: any): any {
      return this.getMemberShipLevel(date);
    }
    getMemberShipLevel(date: string): string {
        var postedSecs: number = Math.round((Date.now() - Date.parse(date)) / 1000);
        var postedMins: number = Math.round(postedSecs / 60);
        var postedHours: number = Math.round(postedMins / 60);
        var postedDays: number = Math.round(postedHours / 24);
        
        if (postedSecs <= 60) {
            return postedSecs + " secs";
        } else if (postedMins <= 60) {
            return postedMins + " mins";
        } else if (postedHours <= 24) {
            return postedHours + " hours";
        } else if (postedDays <= 7)  {
            return postedDays + " days";
        } else if (postedDays <= 30) {
            return Math.round(postedDays / 7) + " weeks";
        } else {
            return Math.round(postedDays / 30) + " months"
        }
    }
  }