import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { Subscription } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  loginForm: FormGroup;
  private subscription: Subscription;
  message: any;
  hide = true;

  constructor(
    private authService: AuthService,
    private alertService: AlertService, 
    private fb: FormBuilder, 
    private router: Router) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
        username_email: [''],
        password: ['']
    });

    this.subscription = this.alertService.getAlert()
    .subscribe(message => {
        this.message = message;
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    var formVal = this.f.username_email.value;
    var formPass = this.f.password.value;

    if (formVal.includes("@")) {
      this.login_user({email:formVal, password:formPass} as User);
    } else {
      this.login_user({username:formVal, password:formPass} as User);
    }
  }

  login_user(login_cred: User): void {
    this.authService.login(login_cred)
    .pipe(first())
    .subscribe(
        data => {
            this.router.navigate(['/']);
        },
        error => {
          this.alertService.error(error);
        });
  }
}
