import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent {

  constructor(private authService: AuthService, private router: Router) { 
    if (this.authService.currUserToken) { 
      this.router.navigate(['/']);
    }
   }

  ngOnInit(): void {
  }

}
