import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  searchIn = new FormControl('');

  constructor(private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  goSearch(): void {
    var paramVal = this.searchIn.value

    if (paramVal != "") {
      this.router.navigate(['/posts'], 
      { queryParams: { search: this.searchIn.value }, queryParamsHandling: 'merge' });
    }
  }

}
