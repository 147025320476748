<app-nav-bar></app-nav-bar>

<div class="container detail-main">
    <div class="row">
        <div class="col-md-5 m">
            <img class="img-fluid fit-image" src="{{postdetail.image_url}}"> 
        </div>
        <div class="col-md my-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item cat-display">
                        <a [routerLink]="['/posts']" [queryParams]="{type: postdetail.type}">
                        {{ postdetail.type }}
                        </a>
                    </li>
                    <li class="breadcrumb-item active cat-display" aria-current="page">
                        <a [routerLink]="['/posts']" [queryParams]="{category: postdetail.category}">
                            {{ postdetail.category }}
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="card border-dark mb-3 post-descr">
                <ul class="card-header nav option-btn-pane">
                    <li class="nav-item">
                        <button mat-button color="primary"><mat-icon>chat_bubble</mat-icon> Contact</button>
                    </li>
                    <li class="nav-item">
                        <button mat-button color="primary"><mat-icon>report</mat-icon> Report</button>
                    </li>
                    <li class="nav-item">
                        <button mat-button color="primary"><mat-icon>share</mat-icon> Share</button>
                    </li>
                </ul>
                <div class="card-body text-dark">
                    <h2 class="post-det-title">{{ postdetail.title }}</h2>
                    <h6 class="time-disp">Posted {{ postdetail.created | getPostTime }} ago</h6>
                    <h5 class="post-title card-title">Details</h5>
                    <p class="post-text card-text">{{ postdetail.description }}</p>
                </div>
            </div>                     
        </div>
    </div>
</div> 
